import {Fragment, useEffect} from "react";

import { zodResolver } from "@hookform/resolvers/zod"
import { useForm, useFieldArray } from "react-hook-form"
import { z } from "zod"

import { Button } from "../components/ui/button"
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "../components/ui/form"
import { Input } from "../components/ui/input"
import {Textarea} from "../components/ui/textarea";
import {formatDateTimeWithTimezone} from "../lib/helper/formatDateTime";

const baseSchema = z.object({
    company: z.string().optional(),
    firstName: z.string().min(1, {
        message: "Vorname wird benötigt",
    }),
    lastName: z.string().min(1, {
        message: "Nachname wird benötigt",
    }),
    email: z.string().min(1, {
        message: "E-Mail Adresse wird benötigt",
    }).email(),
    phone: z.string().optional(),
    message: z.string().optional(),
});

export default function RequestForm(props: any) {

    const {selectedDate, selectedDates, selectedSlot, slotObject, setSubmitting, setSubmitted, properties, selectedSlots} = props;

    const additionalProperties = properties?.additionalProperties || [];

    const dynamicSchema = additionalProperties.reduce((acc: any, prop: any) => {
        acc[prop.value] = prop.required ? z.string().min(1, { message: `${prop.label} wird benötigt` }) : z.string().optional();
        return acc;
    }, {});

    const formSchema = baseSchema.extend(dynamicSchema);

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            company: '',
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            message: '',
            ...additionalProperties.reduce((acc: any, prop: any) => {
                acc[prop.value] = '';
                return acc;
            }, {})
        },
    })

    function onSubmit(values: z.infer<typeof formSchema>) {

        setSubmitting(true);

        const formData = new FormData();

        const getStartAndEndDateTime = (selectedSlot: {date: string, startDateTime: string, endDateTime: string}, selectedDate: {date: string}) => {
            const selectedSlotStartDateTime = selectedSlot.startDateTime.split(':');
            const selectedSlotEndDateTime = selectedSlot.endDateTime.split(':');

            let startDateTime = new Date(selectedDate.date);
            startDateTime.setHours(parseInt(selectedSlotStartDateTime[0], 10));
            startDateTime.setMinutes(parseInt(selectedSlotStartDateTime[1], 10));
            startDateTime.setSeconds(0);
            startDateTime.setMilliseconds(0);

            let endDateTime = new Date(selectedDate.date);
            endDateTime.setHours(parseInt(selectedSlotEndDateTime[0], 10));
            endDateTime.setMinutes(parseInt(selectedSlotEndDateTime[1], 10));
            endDateTime.setSeconds(0);
            endDateTime.setMilliseconds(0);

            return {startDateTime: startDateTime, endDateTime: endDateTime};
        }

        if(selectedSlots && selectedSlots.length > 0) {

            for(let i in selectedSlots) {
                const selectedDate = selectedSlots[i];
                const selectedSlot = selectedSlots[i];

                formData.append(`bookings[${i}][startDateTime]`, `${formatDateTimeWithTimezone(getStartAndEndDateTime(selectedSlot, selectedDate).startDateTime)}`);
                formData.append(`bookings[${i}][endDateTime]`, `${formatDateTimeWithTimezone(getStartAndEndDateTime(selectedSlot, selectedDate).endDateTime)}`);
                formData.append(`bookings[${i}][slotObject]`, slotObject);

                Object.entries(values).forEach(([key, value]) => {
                    if(value !== 'null') {
                        formData.append(`bookings[${i}][properties][${key}]`, value.toString());
                    }
                });

                formData.append(`booking`, '');

            }

        } else {

            if(selectedDates.length > 0) {

                for(let i in selectedDates) {
                    const selectedDate = selectedDates[i];
                    const selectedSlot = selectedDates[i].availableSlots[0];

                    formData.append(`bookings[${i}][startDateTime]`, `${formatDateTimeWithTimezone(getStartAndEndDateTime(selectedSlot, selectedDate).startDateTime)}`);
                    formData.append(`bookings[${i}][endDateTime]`, `${formatDateTimeWithTimezone(getStartAndEndDateTime(selectedSlot, selectedDate).endDateTime)}`);
                    formData.append(`bookings[${i}][slotObject]`, slotObject);

                    Object.entries(values).forEach(([key, value]) => {
                        if(value !== 'null') {
                            formData.append(`bookings[${i}][properties][${key}]`, value.toString());
                        }
                    });

                    formData.append(`booking`, '');

                }

            } else {

                formData.append(`booking[startDateTime]`, `${formatDateTimeWithTimezone(getStartAndEndDateTime(selectedSlot, selectedDate).startDateTime)}`);
                formData.append(`booking[endDateTime]`, `${formatDateTimeWithTimezone(getStartAndEndDateTime(selectedSlot, selectedDate).endDateTime)}`);
                formData.append(`booking[slotObject]`, slotObject);

                Object.entries(values).forEach(([key, value]) => {
                    if(value !== 'null') {
                        formData.append(`booking[properties][${key}]`, value.toString());
                    }
                });

                formData.append(`bookings`, '');

            }

        }

        formData.append('mail[recipientMail]', properties.recipientMail);
        formData.append('mail[senderMail]', properties.senderMail);
        formData.append('mail[subject]', properties.subject);

        formData.append('hasToBeConfirmed', properties.confirmationRequired ? '1' : '0');

        fetch(`${process.env.REACT_APP_API_URL}/api/booking/booking/create`, {method: 'POST', redirect: 'follow', body: formData, credentials: 'include'})
            .then(response => response.json())
            .then(result => {
                setSubmitting(false);
                setSubmitted(true);
            })
            .catch(error => console.log('error', error));
    }

    // useEffect(() => {
    //     if(selectedDates.length > 0) {
    //         console.log(selectedDates)
    //         console.log(selectedDates[0].availableSlots[0])
    //     }
    // }, [selectedDates]);

    // useEffect(() => {
    //     console.log(properties.additionalProperties)
    // }, []);

    return (
        <Fragment>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">

                    <FormField
                        control={form.control}
                        name="company"
                        render={({field}) => (
                            <FormItem>
                                <FormLabel>Firma</FormLabel>
                                <FormControl>
                                    <Input placeholder="Firma" {...field} />
                                </FormControl>
                                <FormMessage/>
                            </FormItem>
                        )}
                    />

                    <div className="grid grid-cols-12 gap-5">
                        <div className="col-span-12 lg:col-span-6">
                            <FormField
                                control={form.control}
                                name="firstName"
                                render={({field}) => (
                                    <FormItem>
                                        <FormLabel>Vorname</FormLabel>
                                        <FormControl>
                                            <Input placeholder="Vorname" {...field} />
                                        </FormControl>
                                        <FormMessage/>
                                    </FormItem>
                                )}
                            />
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                            <FormField
                                control={form.control}
                                name="lastName"
                                render={({field}) => (
                                    <FormItem>
                                        <FormLabel>Nachname</FormLabel>
                                        <FormControl>
                                            <Input placeholder="Nachname" {...field} />
                                        </FormControl>
                                        <FormMessage/>
                                    </FormItem>
                                )}
                            />
                        </div>
                    </div>

                    <div className="grid grid-cols-12 gap-5">
                        <div className="col-span-12 lg:col-span-6">
                            <FormField
                                control={form.control}
                                name="email"
                                render={({field}) => (
                                    <FormItem>
                                        <FormLabel>E-Mail Adresse</FormLabel>
                                        <FormControl>
                                            <Input placeholder="E-Mail Adresse" {...field} />
                                        </FormControl>
                                        <FormMessage/>
                                    </FormItem>
                                )}
                            />
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                            <FormField
                                control={form.control}
                                name="phone"
                                render={({field}) => (
                                    <FormItem>
                                        <FormLabel>Telefonnummer</FormLabel>
                                        <FormControl>
                                            <Input placeholder="Telefonnummer" {...field} />
                                        </FormControl>
                                        <FormMessage/>
                                    </FormItem>
                                )}
                            />
                        </div>
                    </div>

                    {additionalProperties && additionalProperties.length > 0 &&
                        <Fragment>
                            {additionalProperties.map((prop: {label: string, value: string}, index: number) => (
                                <FormField
                                    key={index}
                                    control={form.control}
                                    name={prop.value}
                                    render={({field}) => (
                                        <FormItem>
                                            <FormLabel>{prop.label}</FormLabel>
                                            <FormControl>
                                                <Input placeholder={prop.label} {...field} />
                                            </FormControl>
                                            <FormMessage/>
                                        </FormItem>
                                    )}
                                />
                            ))}
                        </Fragment>
                    }

                    <FormField
                        control={form.control}
                        name="message"
                        render={({field}) => (
                            <FormItem>
                                <FormLabel>Anmerkungen</FormLabel>
                                <FormControl>
                                    <Textarea placeholder="Anmerkungen" {...field} />
                                </FormControl>
                                <FormMessage/>
                            </FormItem>
                        )}
                    />

                    <Button type="submit" className="w-full">
                        {properties.buttonText ? properties.buttonText : 'Termin buchen'}
                    </Button>

                </form>
            </Form>
        </Fragment>
    );
}
